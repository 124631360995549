import { useState, useEffect } from "react";
import { getGeoData } from "../services/services";

export const useGeolocation = () => {
    const [geo, setGeo] = useState(null);

    useEffect(() => {
        if (!navigator.geolocation) {
            console.log("Not Available");
        } else {
            navigator.geolocation.getCurrentPosition((position) => getGdata(position));
            console.log("Available");
        }
    }, []);

    const getGdata = async (position) => {
        const data = await getGeoData(position.coords);
        setGeo(data);
    };

    return [geo];
};
