import axios from "axios"

export const getGeoData = async (coords) => {
    const { latitude, longitude } = coords;
    return await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=26cc58b3ae27a5d2a206d1afaf03c0d5`)
        .then((resp) => resp.json())
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log("weather", err);
        });
};

export const sendForm = async (data) => {
    return await axios.post("https://backend.yakuza.dev/send/feedback", data)
        .then((data) => {
            return data
        })
        .catch((err) => {
            console.log("weather", err);
        });
};
