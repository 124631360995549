import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useGlitch } from "react-powerglitch";

import AnimateBg from "../AnimateBg/AnimateBg";
import Logo from "../Logo/Logo";

import styles from "./MainLayout.module.scss";

const pathVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        // pathLength: 1,
        transition: {
            duration: 1,
            ease: "easeInOut",
            repeat: Infinity,
        },
    },
};

export default function MainLayout() {
    const glitch = useGlitch({ timing: { duration: 4000 } });
    return (
        <motion.div className={styles.wrapper} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className={styles.lineRight} ref={glitch.ref}></div>
            <div className={styles.lineLeft} ref={glitch.ref}></div>
            <Logo />
            {/* <AnimateBg /> */}
            {/* <motion.svg width="100%" height="100%" styles={{ zIndex: -1 }} viewBox="0 0 1600 900" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path variants={pathVariants} initial="hidden" animate="visible" d="M815.5 187L817 148.5L834 148V187H815.5Z" fill="red" />
            </motion.svg> */}

            <Outlet />
        </motion.div>
    );
}
