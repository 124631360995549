import { useState, useEffect, useRef } from "react";
import { motion, useTransform, useMotionValue, cubicBezier } from "framer-motion";
import { useGlitch } from "react-powerglitch";

import GeoTemplate from "../GeoTemplate/GeoTemplate";

import styles from "./Loading.module.scss";

export default function Loading() {
    const [v, setV] = useState(0);
    const [test, setTest] = useState(true);
    const wrapperRef = useRef(null);
    const glitch = useGlitch({ timing: { duration: 3000 } });

    const y = useMotionValue(0);
    const yTransform = useTransform(y, [0, 99], [0, -(window.innerHeight - 270)]);

    const scaleX = useTransform(y, [0, 99], [0, 1]);
    const scaleY = useTransform(y, [30, 60], [2, 1]);
    const rotate = useTransform(y, [60, 100], [0, -90]);

    useEffect(() => {
        const iId = setInterval(() => {
            setV((p) => (p < 99 ? p + 1 : p));
            if (y.get() < 100) {
                y.set(y.get() + 1);
            }
        }, 30);

        return () => {
            clearInterval(iId);
        };
    }, []);

    return (
        <motion.div className={styles.wrapper} initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 30000000000 }}>
            <div className={styles.inner}>
                <GeoTemplate />
                <motion.div className={styles.counter}>
                    <div className={styles.value}>{v}</div>
                    <div className={styles.percent}>%</div>
                </motion.div>
                <motion.div className={styles.wrapperCenterText}>
                    <p className={styles.centerText} ref={glitch.ref}>
                        WELCOME TO YAKUZA
                    </p>
                </motion.div>
                <motion.div className={styles.bar}>
                    <motion.div className={styles.barInner} style={{ width: `${y.get()}%` }}></motion.div>
                </motion.div>
                <p className={styles.bottomText}>HTTPS://YAKUZA.DEV/</p>
            </div>
        </motion.div>
    );
}
