import { useGeolocation } from "../../hooks/useGeolocation";
import { motion } from "framer-motion";
import ShuffleText from "react-shuffle-text";

import styles from "./GeoTemplate.module.scss";

export default function GeoTemplate({ pos }) {
    const [data] = useGeolocation();
    const split = new Date().toString().split(" ");

    if (!data) {
        return null;
    }

    return (
        <motion.div className={styles.wrapper} initial={{ x: pos?.x, y: pos?.y }}>
            <p className={"my-shuffle"}>
                <ShuffleText charFrames={10} charFrameTime={50} content={`BASED IN ${data?.sys?.country} ${data?.name}`} /> <br />
                <ShuffleText charFrames={10} charFrameTime={50} content={`${data?.coord?.lon} ${data?.coord?.lat} / (${split[5]})`} />
            </p>
        </motion.div>
    );
}
