import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Loading from "../Loading/Loading";
import MainLayout from "../MainLayout/MainLayout";

import routes from "../../routes";
const HomePage = lazy(() => {
    return Promise.all([import("../../pages/HomePage/HomePage" /* webpackChunkName: "HomePage" */), new Promise((resolve) => setTimeout(resolve, 3000))]).then(([moduleExports]) => moduleExports);
});

export default function App() {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path={routes.home} element={<MainLayout />}>
                    <Route path={routes.home} element={<HomePage />} />
                </Route>
            </Routes>
        </Suspense>
    );
}
