import { motion } from "framer-motion";
import styles from "./Logo.module.scss";

const pathVariants = {
    hidden: {
        // opacity: 0,
        pathLength: 0,
    },
    visible: {
        // opacity: 1,
        pathLength: 1,
        transition: {
            duration: 3,
            ease: "easeInOut",
        },
    },
};

const textWrapperVariants = {
    hidden: {
        // opacity: 0,
        x: "100%",
    },
    visible: {
        // opacity: 1,
        x: 0,
        transition: {
            duration: 0.5,
            delay: 0.5,
            ease: "easeInOut",
        },
    },
};

const textVariants = {
    hidden: {
        // opacity: 0,
        x: "-120%",
    },
    visible: {
        // opacity: 1,
        x: 0,
        transition: {
            duration: 1,
            delay: 0.6,
            ease: "easeInOut",
        },
    },
};

export default function Logo() {
    return (
        <a href="/" className={styles.wrapper}>
            <motion.svg width="100%" height="100%" viewBox="0 0 344 133" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                    d="M4 3L38 84.5M38 84.5V133M38 84.5L71 6H17M56.5 83.5L86.5 12L116.5 81.5H69M277.5 83L308.5 11L337.5 80.5H291M118.5 85.1876V48.5M118.5 48.5L118 2M118.5 48.5L126.5 40.9255M126.5 40.9255L164 5M126.5 40.9255L168 82M225.508 2C225.508 11.9269 225.508 32.5 224 52.5C222.792 68.5246 212.996 81.1752 200.003 81.5C180 82 173.887 67.5647 172.397 50.5C171 34.5 170.894 15 171 2M225.508 6H275L235 80.5H281"
                    stroke="white"
                    strokeWidth="8"
                />
            </motion.svg>
            <motion.div className={styles.textWrapper} variants={textWrapperVariants} initial="hidden" animate="visible">
                <motion.p className={styles.text} variants={textVariants} initial="hidden" animate="visible">
                    &#60;/&#62; digital studio.
                </motion.p>
            </motion.div>
        </a>
    );
}
